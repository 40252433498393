/* Change the fill color of .node__root circle on hover */
.node__root>circle {
  fill: #F4BF2F;
  transition: fill 0.3s; /* Add a smooth transition effect */
}

.node__root>circle:hover {
  fill:  #F4BF2F;; /* Change the fill color on hover */
}


/* Change the fill color of .node__branch circle on hover */
.node__branch>circle {
  fill: #FF6F2C;
  transition: fill 0.3s; /* Add a smooth transition effect */
}

.node__branch>circle:hover {
  fill: #D55317; /* Change the fill color on hover */
}

/* Change the fill color of .node__leaf circle on hover */
.node__leaf>circle {
  fill:  #FF6F2C;
  transition: fill 0.3s; /* Add a smooth transition effect */
}

.node__leaf>circle:hover {
  fill:  #D55317;; /* Change the fill color on hover */
}


.treeTool {
  display: flex;
  justify-content: center;
  align-items: center;
}


#treeWrapper {
  /* border: red solid 4px; */
  background-color: #f1f1f1;
  width: 100%;
  height: 80vh;
  margin: 3vh;
  align-items: center;
}

text.rd3t-label__title {
fill: #053B4E;
text-anchor:start;

}

path.rd3t-link {
  stroke:#053B4E;
  }

  #content-to-cover {
    position: relative;
    /* Define the width, height, and position of the content to be covered */
  }

.modal__underlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff6f2c80;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 0;
}

.card {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  margin: 20px;
  z-index: 0;
  /* background-color: rgb(253, 253, 252); */
  border-radius: 4px; 
  /* box-shadow: 5px 5px #053B4E; */
  background-image: url('../assets/graphpaper.jpeg');
}


/* //new addition to guide construction */
.card__grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;
  justify-content: center;
}
.card__grid > div {
  display: flex; 
  flex-basis: calc(50% - 20px);  
  justify-content: center;
  flex-direction: column;
}

.card__grid > div > div {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.card__box { 
  margin: 5px; 
  height: 40vh;
  border-style: solid;
  border-color: #053B4E;
  color:#053B4E;
}

.card__box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card__header{
font-size: 1.5rem;
display: flex;                  
text-align: center;
padding:  3px;
}

.card__details {
  font-size: 1.0rem;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px
}

.card__source {
  font-size: 1.0rem;
  text-align: left;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.card__interaction
{
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card__close {
  padding: 5px;
}

.card__save {
  padding: 5px;
}

.btn {
color:grey;
background:none;  
font-size: 30px;
border: none;
}

.btn:hover {
  color:#FF6F2C;
  background:none;  
  font-size: 30px;
  border: none;
  cursor:pointer;
  }


  @media only screen and (max-width: 767px) {
    .card__grid > div {
      display: flex; 
      justify-content: center;
      flex-direction: column;
    }
    .card__grid > div > div {
      display: flex;
      justify-content: center;
    }
    .card__grid {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      position: relative;
      width: 90%;
      justify-content: center;
    }
    .card__box { 
      margin: 5px; 
      height: 20vh;
      border-style: none;
    }

    .card__box img {
      border-radius: 2px; 
    }

    .box1 {order: 3}
    .box2 {order: 1}
    .box3 {order: 4}
    .box4 {order: 2}
  } 
