*{
padding: 0;
margin: 0;
box-sizing: border-box;
}

li {
    list-style:none;
    text-decoration: none;
}

a {
color: white;
}

.navbar {
    min-height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    background-color:#053B4E;
    }

.nav-logoblock  {
    display:flex;
    align-items: center;
}

.nav-brandname {
    text-decoration: none;
      color: white;
      font-size: 1.3rem;
      font-weight: bold;
    }

.nav-menu {
display: flex;
justify-content: space-between;
align-items: center;
gap: 25px;
font-size: 1.2rem;
font-weight: bold;
}

.nav-link {
    transition: 0.4s ease;
    text-decoration: none;
}

.nav-link:hover {
color:#FF6F2C;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.bar{
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
}

/* ability to change colour */
/* .bar:nth-child(1){
    background-color: #006E5D;
}

.bar:nth-child(2){
    background-color: #6B0000;
}

.bar:nth-child(3){
    background-color: #006E5D;
} */

@media(max-width: 768px){
    .hamburger{
        display: block;
    }

    .hamburger.active .bar:nth-child(2){
        opacity:0;
    }

    .hamburger.active .bar:nth-child(1){
        transform: translateY(8px) rotate(45deg);
        background-color: white;
    }

    .hamburger.active .bar:nth-child(3){
        transform: translateY(-8px) rotate(-45deg);
        background-color: white;
    }

    .nav-menu{
        position:fixed;
        left: -100%;
        top: 70px;
        gap: 0;
        flex-direction: column;
        background-color:#FF6F2C;
        width: 100%;
        text-align: center;
        transition: 0.3s;
    }

    .nav-item{
        margin: 8px 0;
    }

    .nav-menu.active{
        left: 0;
    }
}
