.tool-window {
    border: 2px solid #c11a1a; /* Specify border style */
    background-color: aqua;
    padding:5px;
    margin:5px;
  }
  
  .btn-window {
    display: flex;
    flex-direction: column;
    padding: 10px;
   width: 100%;
    align-items: center;
  }
  
  .tree-btn {
    width: 60%;
    -webkit-border-radius: 9;
    -moz-border-radius: 9;
    border-radius: 9px;
    font-family: Arial;
    color: #ffffff;
    font-size: 20px;
    background: #053B4E;
    padding: 10px 20px 10px 20px;
    margin: 10px;
    border: solid #053B4E 2px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .tree-btn:hover {
    background: #FF6F2C;
    text-decoration: none;
  }
  
  .back-btn {
      border: none; 
      border-radius: 0; 
      background-color: transparent; 
      text-decoration: underline;
      cursor: pointer; 
      padding: 0;
  }
  
  
  body.active-modal {
    overflow-y: hidden;
  }
  
  .modal-btn {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
    background-color: orange;
  }
  
  /* .modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }
  
  .overlay {
    background: rgba(49,49,49,0.8);
  }
  .modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
  }
  
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
  } */