.blogcarousel {
  margin-top: 30px;
  margin-bottom: 30px;
}

.blogcarousel__linkwrapper {
  text-decoration: none;
  color: #053B4E;
}

.blogcarousel__header {
  font-size: 1.5rem;
  margin: 50px;
}

.blogcarousel__section {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
}

.blogcarousel__suggestion {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.33% - 50px); /* Calculate the width to be approximately one-third of the container width */
  margin-bottom: 30px; /* Add some margin between sections */
}

.blogcarousel__subheader {
  font-size: 1.0rem;
  margin: 15px;
  height: 50px;
  text-align: center;
}

.blogcarousel__image {
  width: 80%;
    margin: 0px auto 15px auto; 
    display: flex;
    justify-content: center;
    align-items: center;
}

.blogcarousel__subsubheader {
  font-size: 1.0rem;
  margin: 10px;
  text-align: center;
}

  .blogmain__linkwrapper {
    text-decoration: none;
    color: #053B4E;
  }
  
  .blogmain__backlink {
    font-size: 0.8rem;
    margin: 15px 15px 30px 15px;
  }
  
  .blogbody {
    margin-right: 300px; /* Same as the width of the sidenav */
    padding: 0px 10px;
    color: #053B4E;
  }
  
  .blogbody__header {
    font-size: 2.0rem;
    margin: 15px;
  }
  
  .blogbody__citation {
    font-size: 0.8rem;
    margin: 15px;
  }
  
  .blogbody__subheader {
    font-size: 1.5rem;
    margin: 15px;
  }

  .blogcover {
    display:flex;
    flex-direction: column;
    align-items: center;
  }
  
  .blogcover__image {
    width: 80%;
    margin: 5px 15px 0px 15px;
  }
  
  .blogcover__caption {
    font-size: 0.8rem;
    margin: 0px 15px 0px 15px;
    font-style: italic;
    text-align: center;

  }
  
  .blogbody__body {
    font-size: 1.0rem;
    margin: 15px;
  }
  
  .relatedblog {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 300px; /* Same as the width of the sidenav */
    padding: 0px 10px;
    color: #053B4E;
  }

  .relatedblog__header {
    font-size: 1.5rem;
    margin: 15px;
  }

  .relatedblog__section {
    display: flex;
    justify-content: center;
  }
  
  .relatedblog__linkwrapper {
    text-decoration: none;
    color: #053B4E;
  }

  .relatedblog__suggestion {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .relatedblog__subheader {
    font-size: 1.0rem;
    margin: 15px;
    height: 50px;
    text-align: center;   
  }
  
  .relatedblog__image {
    width: 80%;
    margin: 0px auto 15px auto; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .relatedblog__subsubheader {
    font-size: 1.0rem;
    margin: 10px;
    text-align: center;
  }
  

.blogside {
    height: 100%;
    width: 300px;
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    background-color: #c6c6c6;
    color: #053B4E;
    overflow-x: hidden;
    padding-top: 20px;
    display: flex;
    align-items: center;
    text-align: center;
}

.blogtakeout {
   justify-content: center;
}
  
  .blogtakeout__header {
    font-size: 1.5rem;
    margin: 10px;
  }

  .blogtakeout__idea {
    padding: 10px;
  }
  
  .blogtakeout__subheader {
    font-size: 1.0rem;
    margin: 10px;
  }
  
  .blogtakeout__image {
    width: 80%;
  }
  
  
  .blogside__btn {
    background-color: #FF6F2C;
    border-radius: 8px;
    color: white;
    font-size: 20px;
    padding: 10px 20px 10px 20px;
    margin: 10px;
    text-decoration: none;
    border: none;
    cursor: pointer;
  }
  
  .blogside__btn:hover {
    background: #D55317;
    text-decoration: none;
  }

  @media only screen and (max-width: 767px) {

    .blogcarousel__section {

      justify-content: space-evenly;
      flex-wrap: wrap;
      flex-direction: column;
    }
    
    .blogcarousel__suggestion {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 30px; /* Add some margin between sections */
    }



  .blogsinglepage {
      display: flex;
      flex-direction: column; 
  }

  .blogsinglepage__blog{
    order: 1; 
}
  
  .blogsinglepage__side {
      order: 2; 
  }
  
  .blogsinglepage__related{
      order: 3; 
  }

    .blogmain {
      margin-right: 0;
      display: flex;
      flex-direction: column;
      padding: 0; /* Adjust padding as needed */
    }
    
    .blogbody {
      margin-right: auto;
    }
    
  
    .relatedblog {
      margin-top: 30px;
      margin-bottom: 30px;
      margin-right: auto;
    }
  
    .relatedblog__section {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  
    .relatedblog__suggestion {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }
  
    .blogside {
      height: auto;
      width: 100%;
      position: static; /* Remove fixed positioning */
      z-index: 0;
      overflow-x: hidden;
      display: flex;
      align-items: center;
      text-align: center;
      padding-top: 10px; /* Add padding as needed */
    }
  }
  

