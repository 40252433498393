.tool-intro-1 {
    padding: 45px;
    background-image: url('../assets/graphpaper.jpeg');
    background-color: aliceblue;
    background-size: cover;
    background-position: center center;
    line-height: 1.0;
    display: flex;
    align-items:center;
    flex-direction:column;
    color: #053B4E;
}

.circle-highlight {
    width: 140px;
    height: 35px;
    line-height: 25px;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: solid 3px #F4BF2F;
}

.tool-intro-2 {
    padding: 45px;
    background-color: whitesmoke;
    line-height: 1.0;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #053B4E;
}

.tool-profit-intro {
    border: 3px solid #053B4E;
    background-color: white;
    text-transform:uppercase;
    padding: 1rem;
    margin: 20px;
    max-width: 320px;
    border-radius: 10px;
    box-shadow: 8px 8px #053B4E;

}

.tool-intro-3 {
    padding: 30px;
    background-image: url(../assets/magazinepaper.png);
    background-size: cover;
    background-position: center center;
    line-height: 1.2;
    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: center;
    color: #053B4E;
    justify-content: space-evenly;
}

.seperation-bar {
    display: none;
}

.tool-intro-4 {
    padding: 45px;
    background-color: whitesmoke;
    line-height: 1.2;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #053B4E;
    justify-content: space-evenly;
}

.tool-screenshot {
    border: 3px solid  #F4BF2F;
    margin: 20px;
    /* max-width: 320px; */
    border-radius: 10px;
    box-shadow: 8px 8px #F4BF2F;

}

.tool-text-icon-grid {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 14px 0px;
}

.tool-intro-5-wide {
    padding: 25px;
    background-color:rgb(202, 202, 202);
    line-height: 2.0;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #053B4E;
}

.carousel-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    flex-direction: row;
        align-items: center;
}

.carousel-item {
    display: flex;
    padding: 0px 50px;
    /* align-items: center; */
    /* justify-content: center; */
    transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);
}

/* Shared styles for .tool-intro-6 */
.tool-intro-6 {
    padding: 10px 60px;
    background-color: #74526A;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: #ffffff;
}

.tool-text-wide {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-content: center;
    align-items: center;
}

.tool-text-narrow {
    display: none;
}

.tool-intro-narrow {
    display: none;
}

/* STANDARD FORMATTING FOR ALL ELEMENTS */

h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin: 5px 0;
}

h2 {
    font-size: 2.3rem;
}

h3 {
    font-size: 2.0rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.0rem;
}

.tool-text-icons {
    font-size: 3.5rem;
    color: #74526A;
}

p {
    font-size: 1.5rem;
    margin: 5px 0;
}

/* FORMATTING FOR NARROW SCREENS */

@media(max-width: 768px) {
    .tool-text-wide {
        display: none;
    }

    .tool-text-narrow {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .tool-intro-narrow {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .tool-intro-3 {
        flex-direction: column;
    }

    .seperation-bar {
        display: flex;
        width: 40px;
        height: 3px;
        margin: 5px auto;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background-color: #053B4E;
    }

    .tool-intro-4 {
        flex-direction: column;
    }

    /* NARROW VERSION OF CAROUSEL NEEDS TO BE CODED UP */
    .tool-intro-5-wide {
        display: none;
    }

}