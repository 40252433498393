.orange-button {
    background: #FF6F2C;
    color: #FFF;
    display: inline-flex;
    cursor: pointer;
    padding: 2px 15px;
    text-transform: capitalize;
    font-weight: 700;
    border-radius: 10px;
    line-height: normal;
    vertical-align: middle;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-size: 20px;
    height: 30px;
}

.orange-button:hover {
    background: #D55317;
    color: white;
    display: inline-flex;
    cursor: pointer;
    padding: 2px 15px;
    text-transform: capitalize;
    font-weight: 700;
    border-radius: 10px;
    line-height: normal;
    vertical-align: middle;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-size: 20px;
    height: 30px;
}

.grey-button {
    background: #9F999D;
    color: #FFF;
    display: inline-flex;
    cursor: pointer;
    padding: 2px 15px;
    text-transform: capitalize;
    font-weight: 700;
    margin: 8px 0;
    border-radius: 10px;
    line-height: normal;
    vertical-align: middle;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-size: 20px;
    height: 30px;
}

.grey-button:hover {
    background: #727072;
    color: white;
    display: inline-flex;
    cursor: pointer;
    padding: 2px 15px;
    text-transform: capitalize;
    font-weight: 700;
    border-radius: 10px;
    line-height: normal;
    vertical-align: middle;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    font-size: 20px;
    height: 30px;
}