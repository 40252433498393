.notfound{
display: flex;
flex-direction: column ;
height: 85vh;   
justify-content: center;
}

.notfound__header {
font-size: 1.6rem;
padding: 20px;
text-align: center;
color: #053B4E;
}

.notfound__subheader {
    font-size: 1.0rem;
    padding: 20px;
    text-align: center;
    color: #053B4E;
    }
    
