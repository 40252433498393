.footer {
    padding: 20px;
    background-color:#053B4E;
    line-height: 1.2;
    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: center;
    color: white;
    justify-content: space-evenly;
}